<template>
  <v-container grid-list-xs>
    <v-row class="my-10">
      <v-col cols="12" md="7" class="">
        <!-- <h2 class="st-title black">Detalle de la <u>membresía</u></h2> -->
        <!-- <pre>{{ getProductSelected }}</pre> -->
        <!-- <h3>{{ getProductSelected.name }}</h3>
        <div class="" :v-html="getProductSelected.description"></div>
        <p>{{ getProductSelected.membership.name }}</p>
        <p>{{ getProductSelected.membership.days }} dias</p> -->
        <!-- <p>{{ getProductSelected.details }}</p> -->
        <!-- <p>{{ getProductSelected.details.description }}</p> -->
        <v-alert v-if="!getProductSelected" type="warning" dark>
          <p class="white--text">No has selecciona una menbresía</p>
        </v-alert>

        <template v-else>
          <v-card tile elevation="0">
            <v-card-title v-if="getProductSelected" primary-title>
              <h2 class="font-weight-bold">{{ getProductSelected.name }}</h2>
            </v-card-title>
            <v-card-text v-if="getProductSelected">
              <v-row class="h5 black">
                <v-col cols="12" class="py-0">
                  <p>
                    El precio de la membresía es de
                    <strong class="font-weight-bold black h4"
                      >{{ getProductSelected.price }}.00 MXN por
                      {{ getDate }}.</strong
                    >
                  </p>
                </v-col>
                <v-col cols="12" md="12">
                  <!-- <div class="" v-html="getProductSelected.description"></div> -->
                  <p>Beneficios:</p>
                  <ul>
                    <li
                      v-for="(detail, index) in getProductSelected.details"
                      :key="index"
                    >
                      <p>{{ detail.description }}</p>
                    </li>
                  </ul>
                </v-col>
              </v-row>
            </v-card-text>
            <v-row v-else class="py-4">
              <Loading />
            </v-row>
          </v-card>
        </template>
      </v-col>

      <v-col cols="12" md="5">
        <v-card tile elevation="0" class="grey lighten-4 rounded-lg">
          <v-card-title>
            <h2 class="black font-weight-bold mb-0 pb-0">Resumen</h2>
          </v-card-title>

          <v-card-text class="">
            <v-divider class="mb-2"></v-divider>
            <div class="d-flex align-center justify-space-between">
              <h4 class="black h4 font-weight-bold">Total</h4>
              <h4 class="black h4 font-weight-bold">
                MXN {{ getProductSelected.price || 0 }}.00
              </h4>
            </div>
          </v-card-text>
          <v-card-actions class="py-4">
            <v-btn
              :to="{ name: 'MembershipCheckout' }"
              block
              color="primary"
              class="rounded-pill"
              depressed
              dark
            >
              <span class="h5 font-weight-bold">Realizar pago</span></v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import Loading from "../components/dashboard/Loading.vue";
export default {
  components: { Loading },
  data() {
    return {
      value: 0,
    };
  },
  computed: {
    ...mapGetters("cart", ["getProductSelected"]),
    getDate() {
      const value = this.getProductSelected.membership.days;
      switch (true) {
        case value <= 30:
          return "1 Mes";
        case value > 30 && value <= 90:
          return "3 Meses";
        case value > 90 && value <= 180:
          return "6 Meses";
        case value > 180 && value <= 365:
          return "1 Año";

        default:
          return "nada";
          break;
      }
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>